import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './AppComponent/App';
import Login from "./LoginComponent/Login";
import * as serviceWorker from './serviceWorker';
import * as Auth from './AuthService';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Users from "./UsersComponent/Users";
import DataAnalyzer from "./DataAnalyzerComponent/DataAnalyzer";
import ExtractManager from "./ExtractManagerComponent/ExtractManager";
// import Taxonomy from "./TaxonomyEdit/Taxonomy";
import AdminPageRoot from "./SettingsComponent/Settings";

ReactDOM.render(
    <BrowserRouter>
        <Switch>
            <Route path='/login'
                   render={props => Auth.loggedIn() ? <Redirect to={Auth.extractRedirect()}/> : <Login {...props}/>}
            />
            <Route path='/users'
                   render={props => Auth.loggedIn() ? <Users {...props}/> : <Redirect to={Auth.redirectToLogin()}/>}
            />
            {/*<Route path='/taxonomy'*/}
            {/*       render={props => Auth.loggedIn() ? <Taxonomy {...props}/> : <Redirect to={Auth.redirectToLogin()}/>}*/}
            {/*/>*/}
            <Route path='/admin'
                   render={props => Auth.loggedIn() ? <AdminPageRoot {...props}/> : <Redirect to={Auth.redirectToLogin()}/>}
            />
            <Route path='/data-analyzer'
                   render={props => Auth.loggedIn() ? <DataAnalyzer {...props}/> : <Redirect to={Auth.redirectToLogin()}/>}
            />
            <Route path='/extract-manager'
                   render={props => Auth.loggedIn() ? <ExtractManager {...props}/> : <Redirect to={Auth.redirectToLogin()}/>}
            />
            <Route path='/*'
                   render={props => Auth.loggedIn() ? <App {...props}/> : <Redirect to={Auth.redirectToLogin()}/>}
            />
        </Switch>
    </BrowserRouter>,

    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
