import * as React from 'react'
import './DataAnalyzer.css';
import Table from "react-bootstrap/Table";
import axios from 'axios';
import * as _ from 'lodash';
import * as Auth from '../AuthService';
import {properties} from "../properties";
import Pagination from "react-js-pagination";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import {Button, Card} from "react-bootstrap";
import Image from "react-bootstrap/Image";

class ExampleRow extends React.Component {

    render() {
        let value = this.props.example.description;
        const from = value.indexOf(this.props.example.used_token);
        const length = this.props.example.used_token.length;

        return (
            <tr>
                <td>{this.props.example.item}</td>
                <td>{this.props.example.class_name}</td>
                <td>
                    {value.substr(0, from)}<span
                    className='match'>{value.substr(from, length)}</span>{value.substr(from + length)}
                </td>
                <td>{this.props.example.used_token}</td>
            </tr>
        );
    }

}

class ExampleTable extends React.Component {

    state = {};

    render() {

        const rows = this.props.examples.map((v, i) =>
            <ExampleRow key={i+1}
                       index={i + 1}
                       example={v} />);

        return (
            rows.length > 0 ?
            <div>
                <p style={{marginTop:10}}><b>Examples for token - {this.props.token}</b></p>
                <Card border="secondary">
                    <Card.Body className='test'>
                <div>
                <Table bordered hover className='Stats'>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Class</th>
                        <th>Description</th>
                        <th>Token</th>
                    </tr>
                    </thead>
                    <tbody>
                    {rows}
                    </tbody>
                </Table></div></Card.Body></Card>
            </div> : null
        );
    }

}

class ResultRow extends React.Component {

    markAsGarb() {
        let new_rule = {
            name: 'Ignore (' + this.props.item.token +')',
            type: 'garbage',
            template: this.props.item.token,
            markers: [],
            token: this.props.item.token,
            for: {
                _class: this.props.class,
                attribute: 'IGNORE LIST'
            }

        };
        axios.post(`${properties.apiUrl}/api/save/rule`, new_rule, Auth.createConfig())
            .then(json => {
                alert('Rule added to Ignore List!');
                this.props.handleSave()
            })
            .catch(error => console.log(error))
    }

    render() {
        let token = _.replace(_.replace(this.props.item.token, /float/g, '<span style="background-color: #dbffdb">float</span>'), /number/g, '<span style="background-color: yellow">number</span>');
        let freak = (this.props.item.frequency / this.props.size).valueOf().toPrecision(2) * 100;
        let rules = this.props.item.rules.map((rule) => <span className='rule' onClick={() => this.props.openRule(rule)}> {rule.name}</span>);

        return (
            <tr>
                <td>
                    <span dangerouslySetInnerHTML={{__html: token}}/>
                </td>
                <td>
                    {this.props.item.frequency} ({Math.round(freak)} %)
                </td>
                <td>
                    {rules}
                </td>
                <td>
                    <span className='link' onClick={(e) => this.props.showExamples(e, this.props.item.token)}>Examples</span>
                </td>
                <td>
                    <span className='link'
                          onClick={e => { e.preventDefault();
                                          this.props.createRule(this.props.item.token, false)}}>
                        Add rule
                    </span>
                </td>
                <td>
                    <span className='link'
                          onClick={e => {e.preventDefault();
                                         this.markAsGarb()}}>
                        Add to ignore
                    </span>
                </td>
            </tr>
        );
    }

}

export class ResultTable extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            hideExisted: true,
            hideGarbage: false,
            examples: [],
            attributes: [],
            serviceAttributes: [],
            attr: [],
            status: 'Hide',
            results: [],
            addRule: false
        }
    }

    getAttributes(){
        if(this.props.class) {
            axios.get(`${properties.apiUrl}/api/rules/get?class=${this.props.class}`, Auth.createConfig())
                .then(json => this.setState({attributes: json.data}))
                .catch(error => console.log(error));
        }
    }

    componentDidMount() {
        this.getAttributes();
    }

    getArray(array){
        let names = [];
        for(let i = 0; i<array.length; i++){
            names.push(array[i].name)
        }
        return names.join(', ')
    }

    getAnalyze() {
        let info = {
            _class: this.props.class,
            batch: this.props.batch,
            exclude: this.state.status !== 'Hide',
            rules: this.state.attr
        };
        axios.post(`${properties.apiUrl}/api/data-analyzer`,info, Auth.createConfig())
            .then(json => this.setState({results: json.data}))
            .catch(error => console.log(error));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.class !== this.props.class){
            this.getAttributes();
            this.setState({examples: [], results: [], attribute: []})
        }
        if(prevProps.shouldUpdate !== this.props.shouldUpdate) {
            this.getAnalyze();
            this.getAttributes();
        }
    }

    showExamples(event, token) {
        axios.get(`${properties.apiUrl}/api/token/examples?token=${token}&class=${this.props.class}&batch=${this.props.batch}`, Auth.createConfig())
            .then(json => this.setState({examples: json.data, token: token}))
            .catch(error => console.log(error))
    }

    createSelectItems() {
        let items = [];
        if(this.state.attributes) {
            items.push( <MenuItem key={0} value='all'>
                <ListItemText>
                    <strong style ={{fontSize: 12}}>Select all</strong>
                </ListItemText>
            </MenuItem>);
            items.push( <MenuItem key={1} value='none'>
                <ListItemText>
                    <strong style ={{fontSize: 12}}>Deselect all</strong>
                </ListItemText>
            </MenuItem>);
            items.push(<Divider key={2} variant="middle"/>);
            for (let i = 0; i < this.state.attributes.length; i++) {
                items.push(<MenuItem key={i + 3} value={this.state.attributes[i]}>
                    <Checkbox checked={this.state.attr.indexOf(this.state.attributes[i]) > -1}/>
                    <ListItemText style ={{fontSize: 12}}>
                        [ {this.state.attributes[i].attribute} ] {this.state.attributes[i].name}
                    </ListItemText>
                </MenuItem>);
            }
        }
        return items;
    }

    handleSelection(event){
        this.setState({attr: this.checkOperators(event)});
    }
    checkSelection(selectedOperators){
        if(selectedOperators.indexOf('all') > -1) {
            return this.state.attributes
        }else if (selectedOperators.indexOf('none') > -1){
            return [];
        }
        return selectedOperators;
    }

    checkOperators(event){
        let array = [];
        if(event.target.name === 'operators'){
            array = this.checkSelection(event.target.value)
        } else{
            array = this.state.attr;
        }
        return array;
    }

    render() {

        const rows = this.state.results.stats ? this.state.results.stats.map((v, i) =>
            <ResultRow key={i + 1}
                       size={this.state.results.size}
                       index={i + 1}
                       item={v}
                       handleSave={() =>this.props.handleSave()}
                       openRule={(rule) => this.props.openRule(rule)}
                       createRule={(token, isGarb) => this.props.createRule(token, isGarb)}
                       showExamples={(e, token) => this.showExamples(e, token)}
                       class={this.props.class}/>) : [];

        return (
            <div>
                <div style={{'marginTop': 10, 'marginBottom': 10}}>
                    <div style={{marginTop: 5}}>Analysis for
                        class: <b>{this.props.class}</b> (batch <b>{this.props.batch}</b>)
                    </div>
                    <div>Total number of items of the class in the
                        batch: <b>{this.state.results.size ? this.state.results.size.toLocaleString() : ''}</b></div>
                    <div style={{float: 'right', marginTop: -50}}>
                        <div>
                        <Select name='status'
                                style={{width: 200, marginRight: 10, marginTop: 10}}
                                value={this.state.status}
                                onChange={event =>this.setState({status: event.target.value})}
                                renderValue={selected => selected}>
                            <MenuItem key={0} value='Hide'>
                                <ListItemText>
                                    Hide
                                </ListItemText>
                            </MenuItem>
                            <MenuItem key={1} value='Show'>
                            <ListItemText>
                              Show
                            </ListItemText>
                        </MenuItem>
                        </Select>
                            <label className='lbl' htmlFor='operatorCB'>Choose rule:</label>
                            <Select multiple
                                    name='operators'
                                    style={{width: 300, marginRight: 10}}
                                    value={this.state.attr}
                                    onChange={event => this.handleSelection(event)}
                                    renderValue={selected => {
                                        return this.getArray(selected)
                                    }}>
                                {this.createSelectItems()}
                            </Select>
                        </div>
                            <div>
                        <Button style={{marginBottom: 5, float:'right'}} onClick={e => {
                            e.preventDefault();
                            this.getAnalyze()
                        }}>Search</Button>
                        </div>
                    </div>
                </div>
                {this.state.results.stats &&
                <Card border='secondary' style={{marginTop: 40}}>
                    <Card.Body className='test'>

                        <div className='test'><Table bordered hover className='Stats'>
                            <thead>
                            <tr>
                                <th>Token</th>
                                <th>Frequency</th>
                                <th>Existing rules</th>
                                <th colSpan={3}>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {rows}
                            </tbody>
                        </Table></div>
                    </Card.Body></Card>
                }
                {this.props.class && <ExampleTable examples={this.state.examples} token={this.state.token}/>}
            </div>
        );
    }

}

class SourceRow extends React.Component {

    render() {

        return (
            <tr style={{backgroundColor: this.props.markups ?  this.props.markups > 0 ? '#e9f7b5' : '' : ''}}>
                <td>{this.props.index} {this.props.final ?  this.props.final > 0 ? <Image src='/images/star.png'/> : '' : ''}</td>
                <td>{this.props.item.class_name}</td>
                <td>{this.props.item.item}</td>
                {/*<td>{this.props.item.source}</td>*/}
                <td>{this.props.item.description}</td>
                <td style={{'color':"blue", fontSize: 14, textDecorationLine: 'underline', whiteSpace: 'nowrap'}}>
                    <p onClick={() => this.props.handleAnnotation(this.props.item)}>Annotate</p>
                    <p onClick={() => this.props.openQA(this.props.item)}>Open in QA</p>
                    {/*<a target="_blank" style={{'color':"blue"}} rel="noopener noreferrer" href={this.props.item.url}>Open in IH</a>*/}
                </td>
                <td><Image onClick={() => this.props.updateClass(this.props.item)} src="/images/edit-user-blue.png"/></td>
            </tr>
        );
    }

}

export class SourceTable extends React.Component {

    state = {};

    render() {
        if(this.props.source.length > 0) {
            const rows = this.props.source.map((v, i) =>
                <SourceRow key={i + 1 }
                           index={i + 1  + (this.props.currentKey - 1) * properties.previewRowsPerPage}
                           item={v}
                           final={this.props.final[i]}
                           updateClass={(item) => this.props.updateClass(item)}
                           markups={this.props.markups[i]}
                           openQA={item => this.props.openQA(item)}
                           handleAnnotation={item => this.props.handleAnnotation(item)}
                />);

            return (
                <div>
                    <div style={{marginTop: 5}}>{this.props.class ?
                        <div>Results for class: <b>{this.props.class}</b> (batch <b>{this.props.batch}</b>)</div> :
                        <div>Results for batch: <b>{this.props.batch}</b></div>}</div>
                    <div>Total number of items in the batch: <b>{this.props.batchSize.toLocaleString()}</b> (Approved: <b>{this.props.approved.toLocaleString()}</b>)</div>
                    <Table style={{marginTop:5}} bordered hover className='Results'>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Class</th>
                            <th>Item</th>
                            {/*<th>Source</th>*/}
                            <th>Description</th>
                            <th/>
                            <th>Edit class</th>
                        </tr>
                        </thead>
                        <tbody>
                        {rows}
                        </tbody>
                    </Table>
                    {this.props.size > properties.previewRowsPerPage &&
                    <div>
                        <Pagination itemClass="page-item"
                                    linkClass="page-link"
                                    activePage={this.props.currentKey}
                                    itemsCountPerPage={properties.previewRowsPerPage}
                                    totalItemsCount={this.props.size}
                                    pageRangeDisplayed={25}
                                    onChange={(page) => this.props.handlePagination(page - 1)}
                        />
                    </div>
                    }
                </div>
            );
        } else {
            return <h3>No source examples for this class</h3>
        }
    }

}

export default SourceTable;
