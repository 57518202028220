export const properties = {
    apiUrl: process.env.REACT_APP_HOST,
    previewRowsPerPage: 50,
    colors: [
        "#fac8d6",
        "#ffdbdd",
        "#fac8c8",
        "#ffdbeb",
        "#ffdbf5",
        "#fddbff",
        "#f3defa",
        "#f3dbff",
        "#eadbff",
        "#e2dbff",
        "#dbe4ff",
        "#dbf1ff",
        "#dbfff8",
        "#dbffea",
        "#dbffdb",
        "#ebffdb",
        "#f3ffdb",
        "#feffdb",
        "#fff8db",
        "#ffeedb",
        "#ffeadb",
        "#ffe2db",
        "#ffdcdb",
        "#ffdbdb",
        "#dbe4ff",
        "#dbf1ff",
        "#dbfff8",
        "#dbffea",
        "#dbffdb",
        "#ebffdb",
        "#f3ffdb"
    ]
};
