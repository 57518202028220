import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavigationBarRoot from "../NavigationComponent/NavigationBarRoot";
import NavMenu from "../NavbarComponent/Menu";
import {SourceTable, ResultTable} from "../DataAnalyzerComponent/DataAnalyzer";
import axios from "axios";
import * as _ from 'lodash';
import {properties} from "../properties";
import * as Auth from "../AuthService";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import {Button, FormControl, FormGroup, FormLabel} from "react-bootstrap";
import DataAnnotator from "../DataAnnotatorComponent/DataAnnotator";
import RootExtract from "../ExtractManagerComponent/ExtractManager";
import Modal from "react-bootstrap/Modal";
import RuleDesigner from "../RuleDesignerComponent/RuleDesigner";
// import AdminComponent from "../AdminComponent/Admin";
import QAManager from "../QAManagerComponent/QAManager";

class App extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            results: [],
            source: [],
            show: false,
            item: '',
            token: '',
            sample: [],
            itemId: '',
            size: 0,
            approved: 0,
            selected: null,
            batch_size: 0,
            attributes: [],
            currentItem: {
                item: '',
                description: ''
            },
            attr: {
                'attrName': '',
                'class': ''
            },
            markups: [],
            final: [],
            lastKey: 0,
            batch: '',
            rule:''
        }
    }

    getSourceIds(rows = this.state.source) {
        let source = [];
        for (let i = 0; i < rows.length; i++) {
            source.push(rows[i].item)
        }
        return source;
    }

    findAnnotated() {
        axios.get(`${properties.apiUrl}/api/annotations?items=${this.getSourceIds().join(',')}`, Auth.createConfig())
            .then(json => this.setState({markups: json.data.items}))
            .catch(error => console.log(error));
    }

    findFinal() {
        axios.get(`${properties.apiUrl}/api/final/annotations?items=${this.getSourceIds().join(',')}`, Auth.createConfig())
            .then(json => this.setState({final: json.data.items}))
            .catch(error => console.log(error));
    }

    getNumOfApproved() {
        axios.get(`${properties.apiUrl}/api/item/approved?batch=${this.state.batch}&class=${this.state.item}`, Auth.createConfig())
            .then(json => this.setState({approved: json.data.num}))
            .catch(error => console.log(error));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.batch !== this.state.batch) {
            this.setState({shouldUpdate: !this.state.shouldUpdate});
            let srch = this.state.batch === new URLSearchParams(window.location.search.replace('%', '&')).get('batch');
            this.showSource(this.state.batch, '', 0, true, srch);
        } else if (prevState.item !== this.state.item) {
            this.showSource(this.state.batch, this.state.item, 0)
        } else if (prevState.key !== 'home' && this.state.key === 'home') {
            this.showSource(this.state.batch, this.state.item, this.state.lastKey);
        } else if(prevState.selected !== this.state.selected && this.state.selected === null){
            this.showSource(this.state.batch, this.state.item, this.state.lastKey);
        }
        if (prevState.source !== this.state.source) {
            this.findAnnotated();
            this.findFinal()
        }
    }

    showSource(batch, item, from, changeBatch = false, search=false) {
        axios.get(`${properties.apiUrl}/api/data?batch=${batch}&class=${item}&from=${from * properties.previewRowsPerPage}`, Auth.createConfig())
            .then(json => {
                this.setState({
                    source: json.data.items,
                    item: item,
                    size: json.data.size,
                    lastKey: from,
                    batch: batch,
                    batch_size: changeBatch ? json.data.size : this.state.batch_size
                });
                if(search) {
                    let path = window.location.search.replace('%', '&')
                    this.search(new URLSearchParams(path).get('search'), new URLSearchParams(path).get('batch'))
                }
                this.getNumOfApproved();
            })
            .catch(error => {
                console.log(error);
                this.setState({source: []})
            });
    }

    handleSelection(k) {
        this.setState({key: k, currentItem: (k !== 'annotate' && k !== 'qa') ? {} : this.state.currentItem});
    }

    handleAnnotation(item) {
        this.setState({
            currentItem: item,
            key: "annotate"
        });
    }

    handleQA(item) {
        this.setState({
            currentItem: item,
            key: "qa"
        });
    }

    handlePagination(key) {
        this.showSource(this.state.batch, this.state.item, key)
    }

    checkSearch(clear = false) {
        if (this.state.itemId.length > 0 && !clear) {
            this.search(this.state.itemId)
        } else if (clear) {
            this.search('')
        }
    }
    createRule(token, isGarb, rule = '', _class = '', attr = '') {
        // axios.get(`${properties.apiUrl}/api/token/examples?token=${token}&class=${_class ? _class.className : this.state.item}&batch=${this.state.batch}`, Auth.createConfig())
        //     .then(json => {
        //         if( typeof rule !== 'object'){
        //            rule = {};
        //            rule['attribute'] = attr
        //         }
        //         this.setState({sample: json.data.length > 0 ? json.data : [], token: token, key:'designer', rule: rule,isGarb: isGarb, item: _class ? _class.className : this.state.item})
        //     })
        //     .catch(error => console.log(error))
        if( typeof rule !== 'object'){
            rule = {};
            rule['attribute'] = attr
        }
        this.setState({token: token, key:'designer', rule: rule,isGarb: isGarb, item: rule._class ? rule._class : (_class ? _class.className : this.state.item)})
    }

    openRule(rule_id = '', attr = '', item_id =''){
        if (rule_id) {
            axios.get(`${properties.apiUrl}/api/rule/find?id=${rule_id}`, Auth.createConfig())
                .then(json => {
                    let rule = json.data;
                    this.createRule(rule.token, false, rule,'')
                })
                .catch(error => console.log(error));
        } else {
            axios.get(`${properties.apiUrl}/api/search?batch=${this.state.batch}&itemId=${item_id}`, Auth.createConfig())
                .then(json => {
                    this.setState({sample: json.data.items, key:'designer', rule: {'attribute': attr}})
                })
                .catch(error => {
                    this.setState({source: []})
                });
        }
    }

    findNext(source, final = this.state.final){
        let ind = source.indexOf(this.state.currentItem);
        while (true) {
            if (ind < source.length - 1 && final[ind + 1] === 0) {
                this.setState({currentItem: source[ind + 1]});
                break;
            } else if (ind === source.length - 1 && final[0] === 0) {
                this.setState({currentItem: source[0]});
                break;
            } else {
                if (ind < source.length - 1) {
                    ind = ind + 1;
                } else {
                    ind = 0
                }
            }
        }
    }

    getNext(submitted){
        let finals = _.filter(this.state.final, function (o) { return o > 0;});
        if ((submitted ? this.state.approved + 1 : this.state.approved) === this.state.batch_size){
            alert('All items in this batch have final markups');
            this.setState({currentItem: this.state.source[0]});
        }
        else if (finals.length === 50) {
            axios.get(`${properties.apiUrl}/api/data?batch=${this.state.batch}&class=${this.state.item}&from=${(this.state.lastKey + 1) * properties.previewRowsPerPage}`, Auth.createConfig())
                .then(json => {
                    this.setState({
                        source: json.data.items,
                        lastKey: this.state.lastKey + 1,
                    });
                    let source = json.data.items;
                    axios.get(`${properties.apiUrl}/api/final/annotations?items=${this.getSourceIds(json.data.items).join(',')}`, Auth.createConfig())
                        .then(json_next => this.findNext(source, json_next.data.items))
                        .catch(error => console.log(error));

                    this.getNumOfApproved();
                })
                .catch(error => {
                    console.log(error);
                    this.setState({source: []})
                });
        } else {
            this.findNext(this.state.source)
        }
        submitted && this.setState({approved: this.state.approved + 1})
    }

    search(itemId, batch = this.state.batch) {
        if (itemId) {
            axios.get(`${properties.apiUrl}/api/search?batch=${batch}&itemId=${itemId}`, Auth.createConfig())
                .then(json => {
                    this.setState({source: json.data.items, size: json.data.size, itemId: ''})
                })
                .catch(error => {
                    console.log(error);
                    this.setState({source: []})
                });
        } else {
            this.showSource(this.state.batch, this.state.item, this.state.lastKey);
        }
    }

    deleteRule(_class, attr,rule){
        axios.get(`${properties.apiUrl}/api/rules/delete?id=${rule._id}`, Auth.createConfig())
            .then(json => {alert('Rule was successfully deleted'); this.setState({shouldUpdate:!this.state.shouldUpdate})})
            .catch(error => console.log(error))
    }

    render() {
        return (
            <div className='App'>
                <div className='row' style={{marginRight: 0}}>
                    <div className='col-3'>
                        <NavigationBarRoot
                            shouldUpdate={this.state.shouldUpdate}
                            item={this.state.item}
                            batch={this.state.batch}
                            windowHeight={window.innerHeight}
                            attr={this.state.attr}
                            deleteRule={(_class, attr, rule) => this.deleteRule(_class,attr,rule)}
                            addRule={(_class, attr) => this.createRule('','','',_class, attr)}
                            onRuleSelect={(rule) => this.openRule( rule)}
                            handleAttributes={attr => this.setState({attr: attr, item: attr.class, key: 'extract'})}
                            handleClassChange={item => this.setState({
                                item: item,
                                key: "home",
                                rule: '',
                                sample: [],
                                attr: {'attrName': '', 'class': ''}
                            })}
                            handleBatchChange={(batch, source, size) => {
                                this.setState({
                                    batch: batch,
                                    source: source ? source : this.state.source,
                                    size: size ? size : this.state.size,
                                    batch_size: size ? size : this.state.batch_size,
                                    key: "home",
                                    rule: '',
                                    sample: [],
                                    attr: {'attrName': '', 'class': ''}
                                })
                            }}
                        />

                    </div>
                    <div className='col-8' style={{margin: 0}}>
                        <Tabs id="controlled-tab-example" activeKey={this.state.key}
                              onSelect={k => this.handleSelection(k)}>
                            <Tab eventKey="home" title="Source data">
                                <div style={{margin: 5, float: 'right'}}>
                                    <input placeholder='Search by item id' value={this.state.itemId}
                                           onChange={e => this.setState({itemId: e.target.value})}
                                           style={{marginRight: 5}}/>
                                    <Button onClick={() => this.checkSearch()} style={{marginRight: 5}}>Search</Button>
                                    <Button onClick={() => this.checkSearch(true)}>Clear</Button>
                                </div>
                                <SourceTable source={this.state.source}
                                             class={this.state.item}
                                             approved={this.state.approved}
                                             updateClass={(item) => this.setState({selected: item})}
                                             markups={this.state.markups}
                                             final={this.state.final}
                                             batch={this.state.batch}
                                             batchSize={this.state.batch_size}
                                             search={(itemId, clear) => this.search(itemId, clear)}
                                             handleAnnotation={item => this.handleAnnotation(item)}
                                             openQA={item => this.handleQA(item)}
                                             size={this.state.size}
                                             handlePagination={(key) => this.handlePagination(key)}
                                             currentKey={this.state.lastKey + 1}/>
                            </Tab>
                            {(this.state.token || this.state.rule || this.state.sample.length > 0) && <Tab eventKey="designer" title="Rule Designer">
                                <RuleDesigner token={this.state.token}
                                              sample={this.state.sample}
                                              handleSave={() => this.setState({shouldUpdate: !this.state.shouldUpdate})}
                                              rule={this.state.rule}
                                              batch={this.state.batch}
                                              class={this.state.item}/>
                            </Tab>}
                            {this.state.item && <Tab eventKey="analyze" title="Data Analyzer">
                                <ResultTable values={this.state.results}
                                             batch={this.state.batch}
                                             handleSave={() => this.setState({shouldUpdate: !this.state.shouldUpdate})}
                                             shouldUpdate={this.state.shouldUpdate}
                                             openRule = {(rule) => this.openRule(rule.rule_id)}
                                             createRule={(token, isGarb) => this.createRule(token, isGarb)}
                                             class={this.state.item}/>
                            </Tab>}
                            {this.state.currentItem.item &&
                            <Tab eventKey="annotate" title="Annotation Manager">
                                <DataAnnotator currentItem={this.state.currentItem}
                                               onSubmit={() => this.setState({key: "home"})}/>
                            </Tab>
                            }
                            {this.state.currentItem.item &&
                            <Tab eventKey="qa" title="QA Manager">
                                <QAManager item={this.state.currentItem}
                                           getNext={(submitted) => this.getNext(submitted)}
                                />
                            </Tab>
                            }
                            <Tab eventKey="extract" title="Extraction Manager">
                                <RootExtract batch={this.state.batch}
                                                item={this.state.item}
                                                shouldUpdate={this.state.shouldUpdate}
                                                handleUpdate={() => this.setState({shouldUpdate: !this.state.shouldUpdate, key: "home"})}
                                                currentAttr={this.state.attr}
                                                openRule = {(rule_id, attr) => this.openRule(rule_id, attr)}/>
                            </Tab>
                            {/*<Tab eventKey="admin" title="Admin Panel">*/}
                            {/*    <AdminComponent batch={this.state.batch}*/}
                            {/*                    shouldUpdate={this.state.shouldUpdate} />*/}
                            {/*</Tab>*/}
                        </Tabs>
                    </div>
                </div>
                <ClassUpdate onClose={() => this.setState({selected: null, shouldUpdate: !this.state.shouldUpdate})} item={this.state.selected}/>
                <NavMenu history={this.props.history}/>
            </div>
        );
    }
}


class ClassUpdate extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            name: '',
            triedToSubmit: false
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.item !== this.props.item && this.props.item !== null){
            this.setState({name: this.props.item.class_name})
        }
    }

    checkRequired(value) {
        return (value.length === 0 && this.state.triedToSubmit) ? 'error' : null;
    }

    handleSubmit(e){
        e.preventDefault();
        if(this.state.name.length > 0) {
            axios.get(`${properties.apiUrl}/api/item/update_class?name=${this.state.name}&item=${this.props.item.item}`, {})
                .then((json) => {
                    this.props.onClose()
                })
                .catch(error => alert(error.response.data))
                .finally(() => this.setState({
                    triedToSubmit: false
                }));
        } else {
            alert('Field is empty!')
        }
    }

    render() {
        if (this.props.item !== null) {
            return (
                <Modal show onHide={this.props.onClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Update item {this.props.item.item}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <form onSubmit={e => this.handleSubmit(e)}>
                                <FormGroup controlId="email"
                                           validationState={this.checkRequired(this.state.name)}>
                                    <FormLabel column={7}>Class: </FormLabel>

                                    <FormControl type="text" value={this.state.name}
                                                 onChange={e => this.setState({name: e.target.value})}/>
                                </FormGroup>
                                <Button style={{float: 'right'}} type="submit" bsStyle="primary"
                                        loading={this.state.submitInProgress} onClick={e => this.handleSubmit(e)}>Update class</Button>
                            </form>
                        </div>
                    </Modal.Body>
                </Modal>
            );
        } else {
            return null;
        }
    }
}

export default App;
