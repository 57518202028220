import 'bootstrap/dist/css/bootstrap.min.css';
import '../index.css';

import React, { Component } from 'react';
import Button from 'react-bootstrap-button-loader';
import {Card, Form} from "react-bootstrap";

import axios from 'axios';

import { properties } from '../properties.js';
import * as Auth from '../AuthService';
import NavMenu from "../NavbarComponent/Menu";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import './Settings.css';
const FileDownload = require('js-file-download');
require('promise.prototype.finally').shim();

class SettingComponent extends Component {

    render() {
        return (
            <div>
                <Card style={{padding: 10, height:250}}>
                    <Card.Header>{this.props.header}</Card.Header>
                    <Card.Body>
                        <Card.Text>
                            {this.props.text}
                        </Card.Text>
                        {this.props.button}
                    </Card.Body>
                </Card>
            </div>
        );
    }

}

class AdminPageRoot extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            progress: '',
            exportBatch: '',
            deleteBatch: '',
            download: false,
            delete: false,
            deleteFull: false,
            batches: []
        }
    }

    componentDidMount() {
        this.getBatches();
    }

    hubReset () {
        if (!this.state.loading) {
            this.setState({loading: true});
            axios.get(`${properties.apiUrl}/api/data/update`, Auth.createConfig())
                .then(json => alert('Data was reset'))
                .catch(error => console.log(error))
                .finally(() => this.setState({loading:false}))
        }
    }

    uploadFile(e) {
        let formData = new FormData();
        let input = e.target;
        if (input.files.length > 0) {
            for (let file of input.files) {
                formData.append(file.name, file);
            }
            const config = Auth.createConfig();
            config['headers']['Content-Type'] = 'multipart/form-data';
            config['onUploadProgress'] = progressEvent => {
                this.setState({progress: progressEvent.loaded < progressEvent.total ? 'Uploading files...' : 'Processing files...'});
            };
            axios
                .post(`${properties.apiUrl}/api/data/upload`, formData, config)
                .then(resp => {
                    alert('Your files were successfully uploaded!')
                    this.getBatches()
                })
                .catch(error => alert(error.response.data))
                .finally(() => {
                    input.value = '';
                    this.setState({progress: ''});
                });
        }
    }

    getBatches(){
        axios.get(`${properties.apiUrl}/api/batches`)
            .then(json => this.setState({batches: json.data.items}))
            .catch(error => alert(error))
    }

    handleExportBatchSelect(event, batch) {
        event.preventDefault();
        if (batch) {
            this.setState({exportBatch: batch})
        }
    }

    handleDeleteBatchSelect(event, batch) {
        event.preventDefault();
        if (batch) {
            this.setState({deleteBatch: batch})
        }
    }

    delete() {
        let _delete = window.confirm("Are you sure to delete batch " + this.state.deleteBatch  + ' ?');
        if(_delete) {
            this.setState({delete: true});
            axios.get(`${properties.apiUrl}/api/batch/delete?batch=${this.state.deleteBatch}&full=${this.state.deleteFull}`, Auth.createConfig())
                .then(json => {
                    alert('Batch was removed')
                    this.getBatches()
                })
                .catch(error => console.log(error))
                .finally(() => this.setState({
                delete: false,
            }));
        }
    }

    download(){
        this.setState({download: true});
        let url = `${properties.apiUrl}/api/batch/export?batch=${this.state.exportBatch}`;

        // let url = `${properties.apiUrl}/api/mapping/report?batch=${this.state.batch}`;

        const config = Auth.createConfig();
        config['responseType'] = 'blob';

        axios.get(url, config)
            .then(response => {
                const header = response.headers['content-disposition'];
                const filename = /filename=(.*)/.exec(header)[1];
                FileDownload(response.data, filename, filename);
            })
            .catch(error => alert(error))
            .finally(() => this.setState({
                download: false,
            }));

    }

    render() {
        return (
            <div>
                <NavMenu history={this.props.history}/>
                <div style={{marginTop: 55, marginLeft: 5, overflowX:'hidden'}}>
                    <div className='row'>
                        <div className='col-md-4'>
                    <SettingComponent header={'Re-read Data from DataBase'}
                                        button = {<Button loading={this.state.loading}
                                                          onClick = {() => this.hubReset()}
                                                          disabled = {this.state.loading}
                                                          variant="primary">Data reset</Button>}
                                        text={'Click here to re-read Data from DataBase. Could take a while...'}
                    />
                        </div>
                        <div className='col-md-4'>
                        <SettingComponent header={'Upload Items'}

                                          button = {<div>
                                                        <input type="file"
                                                               onChange={e => this.uploadFile(e)}
                                                               multiple
                                                               disabled={this.state.progress !== ''}/>
                                                        <span>{this.state.progress}</span>
                                                    </div>}
                                          text={'Select one or multiple files to upload. Could take a while...'}
                        />
                        </div>
                        <div className='col-md-4'>
                        <SettingComponent header={'Dump batch'}
                                          button = {<div style={{display:'inline'}}>
                                              <Autocomplete
                                                  id="combo-box-batch"
                                                  options={this.state.batches}
                                                  getOptionLabel={option => option}
                                                  style={{margin: '5px 0', width: 300, display: 'inline-flex'}}
                                                  onChange={(event, values) => this.handleExportBatchSelect(event, values)}
                                                  onClose={(event) => this.handleExportBatchSelect(event, '')}
                                                  renderInput={params => (
                                                      <TextField {...params} label="Search for batches" variant="outlined" fullWidth/>
                                                  )}
                                                  renderOption={option => (
                                                      <React.Fragment>
                                                          <span>{option}</span>
                                                      </React.Fragment>
                                                  )}
                                              />
                                              <Button loading={this.state.download}
                                                      style={{display:'inline', marginLeft: 5}}
                                                      onClick = {() => this.download()}
                                                      disabled = {this.state.download}
                                                      variant="primary">Download</Button>
                                          </div>}
                                          text={'Select batch and press \'Download\'. Could take a while...'}
                        />
                    </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-4'>
                            <SettingComponent header={'Delete batch'}
                                              button = {<div>
                                                  <Autocomplete
                                                      id="combo-box-batch"
                                                      options={this.state.batches}
                                                      getOptionLabel={option => option}
                                                      style={{margin: '5px 0'}}
                                                      onChange={(event, values) => this.handleDeleteBatchSelect(event, values)}
                                                      onClose={(event) => this.handleDeleteBatchSelect(event, '')}
                                                      renderInput={params => (
                                                          <TextField {...params} label="Search for batches" variant="outlined" fullWidth/>
                                                      )}
                                                      renderOption={option => (
                                                          <React.Fragment>
                                                              <span>{option}</span>
                                                          </React.Fragment>
                                                      )}
                                                  />
                                                  <Form.Check
                                                        type='checkbox'
                                                        id={`default-checkbox`}
                                                        onChange={e => this.setState({deleteFull: !this.state.deleteFull})}
                                                        label={`Delete with it content`}
                                                  />
                                                  <Button loading={this.state.delete}
                                                          onClick = {() => this.delete()}
                                                          disabled = {this.state.delete}
                                                          variant="primary">Delete</Button>
                                              </div>}
                                              text={'Select batch and press \'Delete\'. Could take a while...'}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default AdminPageRoot;
