import './Menu.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../index.css';

import React, { Component } from 'react';
import {Modal, FormGroup, FormControl, Image, FormLabel, Navbar, Nav} from 'react-bootstrap';
import Button from 'react-bootstrap-button-loader';

import axios from 'axios';

import { properties } from '../properties.js';
import * as Auth from '../AuthService';

var Recaptcha = require('react-recaptcha');
const FileDownload = require('js-file-download');

class ContactUsModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            phone: '',
            message: '',
            recaptchaResponse: null,
            triedToSubmit: false,
            submitInProgress: false
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.show && !prevProps.show &&  Auth.loggedIn()) {
            const user = Auth.getUser();
            this.setState({
                name: `${user.firstName} ${user.lastName}`,
                email: `${user.email}`,
                phone: `${user.phone ? user.phone : ''}`
            })
        }
    }
    //TODO: fix problems with recaptcha

    handleSubmit(e) {
        e.preventDefault();
        this.setState({triedToSubmit: true});
        // if (!this.state.recaptchaResponse) {
        //     document.getElementById('g-recaptcha').classList.add('empty');
        // } else
            if (this.state.name.length > 0 && this.state.email.length > 0 && this.state.message.length > 0) {

            this.setState({submitInProgress: true});
            axios.post(`${properties.apiUrl}/api/email/contact`, {
                    'name': this.state.name,
                    'email': this.state.email,
                    'phone': this.state.phone,
                    'message': this.state.message,
                    'recaptchaResponse': this.state.recaptchaResponse
                }, Auth.createConfig())
                .then(json => {
                    this.setState({submitInProgress: false});
                    this.close();
                })
                .catch(error => alert(error.response.data));
        }
    }

    checkRequired(value) {
        return (value.length === 0 && this.state.triedToSubmit) ? 'error' : null;
    }

    verifyRecaptcha(response) {
        if (response) {
            this.setState({recaptchaResponse: response});
            document.getElementById('g-recaptcha').classList.remove('empty');
        }
    }

    close() {
        this.setState({
            name: '',
            email: '',
            phone: '',
            message: '',
            recaptchaResponse: null,
            triedToSubmit: false,
            submitInProgress: false
        });
        this.props.onClose();
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={() => this.close()}>
                <form className="Contact-form" onSubmit={e => this.handleSubmit(e)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Contact Us</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormGroup controlId="name" className="required"
                                   validationState={this.checkRequired(this.state.name)}>
                            <FormLabel>Name</FormLabel>
                            <FormControl type="text" value={this.state.name}
                                         onChange={e => this.setState({name: e.target.value})}/>
                        </FormGroup>
                        <FormGroup controlId="email" className="required"
                                   validationState={this.checkRequired(this.state.email)}>
                            <FormLabel>Email</FormLabel>
                            <FormControl type="email" value={this.state.email}
                                         onChange={e => this.setState({email: e.target.value})}/>
                        </FormGroup>
                        <FormGroup controlId="phone">
                            <FormLabel>Phone Number</FormLabel>
                            <FormControl type="text" value={this.state.phone}
                                         onChange={e => this.setState({phone: e.target.value})}/>
                        </FormGroup>
                        <FormGroup controlId="message" className="required"
                                   validationState={this.checkRequired(this.state.message)}>
                            <FormLabel>Message</FormLabel>
                            <FormControl as="textarea" rows="5" value={this.state.message}
                                         onChange={e => this.setState({message: e.target.value})}/>
                        </FormGroup>
                            <Recaptcha
                                sitekey="6LfAEpwUAAAAAPvayyQjTN3-QzUdY1WXDQPRfgVD"
                                render="explicit"
                                verifyCallback={response => this.verifyRecaptcha(response)}
                            />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" bsStyle="primary" loading={this.state.submitInProgress}>Submit</Button>
                    </Modal.Footer>
                </form>
            </Modal>
        );
    }
}

export class Menu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showContactForm: false,
            triedToSubmit: false,
            submitInProgress: false
        }
    }

    logout() {
        Auth.logout();
        this.props.history.replace(Auth.redirectToLogin());
    }

    render() {
        return (
            <div>
                <ContactUsModal show={this.state.showContactForm} onClose={() => this.setState({showContactForm: false})}/>
                <div id="menu">
                {this.props.logout !== false &&
                <Button bsStyle="link" title="Download QRG">
                    <a href={"files/1000-100-DAT-00007 03 Quick reference guide - DIGATEX Data Mapper.pdf"} target="_blank" rel="noopener noreferrer">
                        <Image style={{maxWidth:24}} src="/images/qrg.png"/>
                    </a>
                </Button> }
                    {this.props.contactUs !== false &&
                    <Button id="contactUsLink" bsStyle="link" onClick={() => this.setState({showContactForm: true})} title="Contact Us">
                        <Image src="/images/email.png"/>
                    </Button>}
                    {this.props.logout !== false &&
                    <Button id="logout" bsStyle="link" onClick={() => this.logout()} title="Log Out">
                        <Image src="/images/logout.png"/>
                    </Button>}
                </div>
            </div>
        );
    }
}

class NavMenu extends Component{

    render() {
        return(
            this.props.logout !== false ?
            <Navbar fixed="top" className="NavBar-logo">
                <Navbar.Brand href="#home">
                    <img
                        src="/images/digatex.png"
                        height="50"
                        className="d-inline-block align-top"
                    />
                </Navbar.Brand>
                <Nav className="mr-auto"
                     onSelect={(eventKey) => this.setState({activeKey: eventKey})}
                     activeKey={this.state ? this.state.activeKey : "1"}>
                    <Nav.Link eventKey="1" href="/">Home</Nav.Link>
                    <Nav.Link eventKey="2" href="/users">Users</Nav.Link>
                    {/*<Nav.Link eventKey="3" href="/taxonomy">Taxonomy edit</Nav.Link>*/}
                    <Nav.Link eventKey="4" href="/admin">Administrative Panel</Nav.Link>
                </Nav>
                <Menu history={this.props.history}/>
            </Navbar> :  <Menu history={this.props.history} logout={false}/>

        );
    }

}

export default NavMenu;
