import React from "react";
import {Button, Card, Form, FormControl, FormGroup, FormLabel} from "react-bootstrap";
import Table from "react-bootstrap/Table";
import axios from "axios";
import {properties} from "../properties";
import * as Auth from "../AuthService";
import * as _ from "lodash";
import './AdvancedStatistics.css'


class ExampleRow extends React.Component {

    render() {
        let value = this.props.example.description;
        const from = value.indexOf(this.props.example.used_token);
        const length = this.props.example.used_token.length;

        return (
            <tr>
                <td>{this.props.example.item}</td>
                <td>{this.props.example.class_name}</td>
                <td>
                    {value.substr(0, from)}<span
                    className='match'>{value.substr(from, length)}</span>{value.substr(from + length)}
                </td>
                <td>{this.props.example.used_token}</td>
                <td>{this.props.example.value}</td>
                <td className='link' onClick={() => this.props.openRule(this.props.example.rule_id)}>{this.props.example.used_rule}</td>
            </tr>
        );
    }

}

class ExampleTable extends React.Component {

    state = {};

    render() {

        const rows = this.props.examples ? this.props.examples.map((v, i) =>
            <ExampleRow key={i+1}
                        index={i + 1}
                        example={v}
                        openRule={(rule) => this.props.openRule(rule)} />) : [];

        return (
            rows.length > 0 ?
                <div>
                    <p style={{marginTop:10}}><b>Examples for token - {this.props.token}</b></p>
                    <Card border="secondary">
                        <Card.Body className='test'>
                            <div>
                                <Table bordered hover className='Stats'>
                                    <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Class</th>
                                        <th>Description</th>
                                        <th>Token</th>
                                        <th>Value</th>
                                        <th>Used Rule</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {rows}
                                    </tbody>
                                </Table></div></Card.Body></Card>
                </div> : null
        );
    }

}

class StatisticsRow extends React.Component {

    render() {
        let rules = this.props.item.rules.map((v,i) => <span className='rule' key={i} onClick={() => this.props.openRule(v.rule_id)}>{v.name}</span>);
        let token = _.replace(_.replace(this.props.item.token, /float/g, '<span style="background-color: #dbffdb">float</span>'), /number/g, '<span style="background-color: yellow">number</span>');
        let freak = (this.props.item.frequency / this.props.size).valueOf().toPrecision(2) * 100;

        return (
            <tr>
                <td>
                    <span dangerouslySetInnerHTML={{__html: token}}/>
                </td>
                <td>
                    {this.props.item.frequency} ({Math.round(freak)} %)
                </td>
                <td>
                    {rules}
                </td>
                <td>
                    <span className='link' onClick={(e) => this.props.showExamples(e, this.props.item.token)}>Examples</span>
                </td>
            </tr>
        );
    }

}

export class StatisticsTable extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            examples: [],
            attributes: [],
            serviceAttributes: [],
            attr: [],
            asc: true,
            status: 'Hide',
            results: [],
            addRule: false
        }
    }

    getAttributes(){
        if(this.props.class) {
            axios.get(`${properties.apiUrl}/api/attributes?class=${this.props.class}`, Auth.createConfig())
                .then(json => this.setState({attributes: json.data.items}))
                .catch(error => console.log(error));
        }
    }

    componentDidMount() {
        this.setState({attribute:this.props.currentAttr.attrName});
        this.getAttributes();
    }

    getArray(array){
        let names = [];
        for(let i = 0; i<array.length; i++){
            names.push(array[i].name)
        }
        return names.join(', ')
    }

    getAnalyze(asc) {
        axios.get(`${properties.apiUrl}/api/extraction/statistics?batch=${this.props.batch}&class=${this.props.class}&attr=${this.state.attribute}&asc=${asc}`,Auth.createConfig())
            .then(json => this.setState({results: json.data, asc: asc}))
            .catch(error => console.log(error));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.class !== this.props.class){
            this.getAttributes();
            this.setState({examples: [], results: [], attribute: []})
        }
        if (prevProps.currentAttr !== this.props.currentAttr){
            this.setState({attribute:this.props.currentAttr.attrName});
        }
        // if(prevProps.shouldUpdate !== this.props.shouldUpdate) {
        //     this.getAnalyze();
        //     this.getAttributes();
        // }
    }

    showExamples(event, token) {
        axios.get(`${properties.apiUrl}/api/extraction-value/examples?token=${token}&class=${this.props.class}&attr=${this.state.attribute}&batch=${this.props.batch}`, Auth.createConfig())
            .then(json => this.setState({examples: json.data, token: token}))
            .catch(error => console.log(error))
    }

    createSelectItems() {
        let items = [];
        if(this.state.attributes) {
            for (let i = 0; i < this.state.attributes.length; i++) {
                if(this.state.attributes[i].attributeName !== 'IGNORE LIST') {
                    let v = this.state.attributes[i];
                    items.push(<option key={i}
                                       value={v.attributeName}
                                       selected={this.state.attribute === v.attributeName}>
                        {v.attributeName}
                    </option>);
                }
            }
        }
        return items;
    }

    render() {

        const rows = this.state.results.stats ? this.state.results.stats.map((v, i) =>
            <StatisticsRow key={i + 1}
                       size={this.state.results.size}
                       index={i + 1}
                       item={v}
                       // handleSave={() =>this.props.handleSave()}
                       openRule={(rule) => this.props.openRule(rule)}
                       // createRule={(token, isGarb) => this.props.createRule(token, isGarb)}
                       showExamples={(e, token) => this.showExamples(e, token)}
                       class={this.props.class}/>) : [];

        return (
            <div>
                <div style={{'marginTop': 10, 'marginBottom': 10}}>
                    <div style={{marginTop: 5}}>Analysis for
                        class: <b>{this.props.class}</b> (batch <b>{this.props.batch}</b>)
                    </div>
                    <div>Total number of items of the class in the
                        batch that were covered by rules: <b>{this.state.results.size ? this.state.results.size.toLocaleString() : ''}</b></div>
                    <div style={{float: 'right', marginTop: -50}}>
                        <div>
                            <FormLabel  style={{display:'inline'}}>Attribute:</FormLabel>
                            <FormControl as='select'
                                    style={{display:'inline', width:250}}
                                    value={this.state.attribute}
                                    onChange={event => this.setState({attribute: event.target.value})}>
                                {this.createSelectItems()}
                            </FormControl>
                            <Button style={{marginBottom: 5,marginLeft: 5,display:'inline', float:'right'}} onClick={e => {
                                this.getAnalyze(true)
                            }}>Search</Button>
                        </div>
                    </div>
                </div>
                {this.state.results.stats &&
                <Card border='secondary' style={{marginTop: 40}}>
                    <Card.Body className='test'>

                        <div className='test'><Table bordered hover className='Stats'>
                            <thead>
                            <tr>
                                <th>Token</th>
                                <th>Frequency
                                   { !this.state.asc ? <img src='/images/arrow-up.png' onClick={() => this.getAnalyze(true)}/>
                                   :
                                    <img src='/images/arrow-down.png' onClick={() => this.getAnalyze(false)}/>}
                                </th>
                                <th>Rules</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {rows}
                            </tbody>
                        </Table></div>
                    </Card.Body></Card>
                }
                {this.props.class && <ExampleTable openRule={(id) => this.props.openRule(id)} examples={this.state.examples} token={this.state.token}/>}
            </div>
        );
    }

}

class AdvancedStatisticsRoot extends React.Component {

    render() {
        return (
            <div>
               <StatisticsTable />
            </div>
        );
    }

}
